import { Heading, Text } from '-/aslan';
import { useT } from '-/i18n';

export function ClientError({ status }: { status: number }) {
  const customResponses = [400, 401, 403, 404];
  const value = customResponses.includes(status) ? status : 'default';
  const t = useT('translation', `error.client.${value}`);
  return (
    <>
      <Heading as="h1" size="3xl" className="font-serif">
        {t('line1')}
      </Heading>
      <Text size="lg">{t('line2')}</Text>
      {value === 404 ? <Text size="lg">{t('line3')}</Text> : null}
    </>
  );
}

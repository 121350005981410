import { Heading, Text } from '-/aslan';
import { useT } from '-/i18n';

export function ServerError() {
  const t = useT('translation', 'error.server');
  return (
    <>
      <Heading as="h1" size="3xl" className="font-serif">
        {t('line1')}
      </Heading>
      <Text size="lg">{t('line2')}</Text>
      <Text size="lg">
        {t('line3')}{' '}
        <a className="underline" href="mailto:beta@theleap.co">
          {t('contactUs')}
        </a>
      </Text>
    </>
  );
}

import { twMerge } from 'tailwind-merge';

import { LogoExtendedTwirl } from '-/aslan/icons/LogoExtendedTwirl';
import { useT } from '-/i18n';
import ButtonLink from '~/creatorAdmin/components/ButtonLink';
import { homePath } from '~/creatorAdmin/helpers/routes';

import type { PropsWithChildren } from 'react';

interface ErrorLayoutProps {
  children: PropsWithChildren<React.ReactNode>;
  className?: string;
}

export const ErrorLayout = ({ children, className }: ErrorLayoutProps) => {
  const t = useT('translation', 'error');

  return (
    <div
      className={twMerge('w-full min-h-full text-center', className)}
      data-testid="error-layout"
    >
      <div className="w-full min-h-full error-page-background-image -z-10" />
      <main className="flex flex-col space-y-10 items-center justify-center mx-auto w-full max-w-xl p-4">
        {/* Header */}
        <LogoExtendedTwirl wiggle />
        {/* Error Response Custom Messaging */}
        {children}
        {/* Footer */}
        <ButtonLink
          color="yellow"
          to={homePath()}
          isRounded
          isFullWidth
          className="my-3 md:w-1/2 md:pr-2"
        >
          {t('layout.goBackToHome')}
        </ButtonLink>
      </main>
    </div>
  );
};

import {
  Links,
  Meta,
  Outlet,
  Scripts,
  isRouteErrorResponse,
  useRouteError } from
'@remix-run/react';
import * as Sentry from '@sentry/remix';
import { useEffect } from 'react';
import rdtStylesheet from 'remix-development-tools/index.css';

import { ClientError, ErrorLayout, ServerError } from '-/aslan';
import { useT } from '-/i18n';
import { logBoundaryErrors } from '-/utils';
import styles from '~/styles/tailwind.css';

import type { LinksFunction } from '@remix-run/server-runtime';

export const links: LinksFunction = () => {
  return [
  {
    rel: 'preload',
    as: 'font',
    href: '/creator/fonts/favorit-bold.woff2',
    type: 'font/woff2',
    crossOrigin: 'anonymous'
  },
  {
    rel: 'preload',
    as: 'font',
    href: '/creator/fonts/favorit-regular.woff2',
    type: 'font/woff2',
    crossOrigin: 'anonymous'
  },
  {
    rel: 'preload',
    as: 'font',
    href: '/creator/fonts/tobias-light.woff2',
    type: 'font/woff2',
    crossOrigin: 'anonymous'
  },
  {
    rel: 'apple-touch-icon',
    href: '/creator/apple-touch-icon.png',
    type: 'image/png',
    sizes: '180x180'
  },
  {
    rel: 'mask-icon',
    href: '/creator/safari-pinned-tab.svg',
    color: '#f0edee'
  },
  {
    rel: 'icon',
    href: '/creator/favicon-16x16.png',
    sizes: '16x16',
    type: 'image/png'
  },
  {
    rel: 'icon',
    href: '/creator/favicon-32x32.png',
    sizes: '32x32',
    type: 'image/png'
  },
  { rel: 'stylesheet', href: styles },
  ...(process.env.NODE_ENV === 'development' ?
  [{ rel: 'stylesheet', href: rdtStylesheet }] :
  [])];

};

let AppExport = App;

if (process.env.NODE_ENV === 'development') {
  const { withDevTools } = require('remix-development-tools');
  AppExport = withDevTools(AppExport);
}

function App() {
  return <Outlet />;
}

export function ErrorBoundary() {
  const error = useRouteError();
  const t = useT('translation', 'error');
  logBoundaryErrors(error, Sentry);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // Dynamic import for newrelicBrowser as it breaks on server
      import('newrelicBrowser').
      then(({ sendCustomError }) => {
        sendCustomError((error as Error));
      }).
      catch((importError) => {
        console.error('Failed to load newrelicBrowser:', importError);
      });
    }
  }, [error]);

  return (
    <html lang="en" className="leading-normal">
      <head>
        <title>{`${t('layout.title')} | TheLeap`}</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body className="antialiased h-full bg-gray-100 text-brown-900">
        <ErrorLayout>
          {isRouteErrorResponse(error) ?
          <ClientError status={error.status} /> :

          <ServerError />}

        </ErrorLayout>
        <Scripts />
      </body>
    </html>);

}

export default Sentry.withSentry(AppExport);